const _temp0 = require("../../../static/images/presence/anniversary.webp");
const _temp1 = require("../../../static/images/presence/beyondlight.webp");
const _temp2 = require("../../../static/images/presence/control.webp");
const _temp3 = require("../../../static/images/presence/crucible.webp");
const _temp4 = require("../../../static/images/presence/destinylogo.webp");
const _temp5 = require("../../../static/images/presence/doubles.webp");
const _temp6 = require("../../../static/images/presence/dungeon.webp");
const _temp7 = require("../../../static/images/presence/explore.webp");
const _temp8 = require("../../../static/images/presence/forge.webp");
const _temp9 = require("../../../static/images/presence/gambit.webp");
const _temp10 = require("../../../static/images/presence/hauntedforest.webp");
const _temp11 = require("../../../static/images/presence/hunter.webp");
const _temp12 = require("../../../static/images/presence/important.webp");
const _temp13 = require("../../../static/images/presence/ironbanner.webp");
const _temp14 = require("../../../static/images/presence/lightfall.webp");
const _temp15 = require("../../../static/images/presence/lostsector.webp");
const _temp16 = require("../../../static/images/presence/menagerie.webp");
const _temp17 = require("../../../static/images/presence/privatecrucible.webp");
const _temp18 = require("../../../static/images/presence/raid.webp");
const _temp19 = require("../../../static/images/presence/reckoning.webp");
const _temp20 = require("../../../static/images/presence/seasonchosen.webp");
const _temp21 = require("../../../static/images/presence/seasondeep.webp");
const _temp22 = require("../../../static/images/presence/seasondefiance.webp");
const _temp23 = require("../../../static/images/presence/seasonhaunted.webp");
const _temp24 = require("../../../static/images/presence/seasonlost.webp");
const _temp25 = require("../../../static/images/presence/seasonplunder.webp");
const _temp26 = require("../../../static/images/presence/seasonrisen.webp");
const _temp27 = require("../../../static/images/presence/seasonseraph.webp");
const _temp28 = require("../../../static/images/presence/seasonsplicer.webp");
const _temp29 = require("../../../static/images/presence/seasonwish.webp");
const _temp30 = require("../../../static/images/presence/seasonwitch.webp");
const _temp31 = require("../../../static/images/presence/shadowkeep.webp");
const _temp32 = require("../../../static/images/presence/socialall.webp");
const _temp33 = require("../../../static/images/presence/storypvecoopheroic.webp");
const _temp34 = require("../../../static/images/presence/strikes.webp");
const _temp35 = require("../../../static/images/presence/thenine.webp");
const _temp36 = require("../../../static/images/presence/thewitchqueen.webp");
const _temp37 = require("../../../static/images/presence/titan.webp");
const _temp38 = require("../../../static/images/presence/trialsofosiris.webp");
const _temp39 = require("../../../static/images/presence/vexoffensive.webp");
const _temp40 = require("../../../static/images/presence/warlock.webp");
const _temp41 = require("../../../static/images/presence/wellspring.webp");
module.exports = {
  "anniversary": _temp0,
  "beyondlight": _temp1,
  "control": _temp2,
  "crucible": _temp3,
  "destinylogo": _temp4,
  "doubles": _temp5,
  "dungeon": _temp6,
  "explore": _temp7,
  "forge": _temp8,
  "gambit": _temp9,
  "hauntedforest": _temp10,
  "hunter": _temp11,
  "important": _temp12,
  "ironbanner": _temp13,
  "lightfall": _temp14,
  "lostsector": _temp15,
  "menagerie": _temp16,
  "privatecrucible": _temp17,
  "raid": _temp18,
  "reckoning": _temp19,
  "seasonchosen": _temp20,
  "seasondeep": _temp21,
  "seasondefiance": _temp22,
  "seasonhaunted": _temp23,
  "seasonlost": _temp24,
  "seasonplunder": _temp25,
  "seasonrisen": _temp26,
  "seasonseraph": _temp27,
  "seasonsplicer": _temp28,
  "seasonwish": _temp29,
  "seasonwitch": _temp30,
  "shadowkeep": _temp31,
  "socialall": _temp32,
  "storypvecoopheroic": _temp33,
  "strikes": _temp34,
  "thenine": _temp35,
  "thewitchqueen": _temp36,
  "titan": _temp37,
  "trialsofosiris": _temp38,
  "vexoffensive": _temp39,
  "warlock": _temp40,
  "wellspring": _temp41
}